import React, { useRef, useState } from "react";
import Table from "../generic/Table/Table";
import { FieldMappingInterface, ReportInterface } from "../../interfaces/ReportInterface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faUpload } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "../generic/Tooltip/Tooltip";
import { useTranslation } from 'react-i18next';
import '../../i18n';
import Modal from "../generic/Modal/Modal";
import DateRangeForm from "../generic/Form/DateRangeForm";
import './ReportsTable.css';
import { useAuth } from "../../context/generic/AuthContext";
import ShopSelect from "../Shop/Forms/ShopSelect";
import { useForm, FormProvider } from "react-hook-form";

interface ReportsTableProps {
  reports: ReportInterface[];
  onEdit: (report: ReportInterface) => void;
  onDelete: (report: ReportInterface) => void;
  onUpload: (file: File, report: ReportInterface, startDate?: Date, endDate?: Date, shopId?: number) => void;
}

const ReportsTable: React.FC<ReportsTableProps> = ({ reports, onEdit, onDelete, onUpload }) => {
  const { user } = useAuth();
  const isAdmin = user && user.roles && user.roles.includes('ROLE_ADMIN');

  const { t: tProviders } = useTranslation('providers');
  const { t: tReport } = useTranslation('report');
  const fileInputRefs = useRef(new Map());

  // États pour les modales existantes
  const [isModalForShopPerformanceOpen, setIsModalForShopPerformanceOpen] = useState(false);
  const [isModalForCustomerReportOpen, setIsModalForCustomerReportOpen] = useState(false);
  const [isModalForCustomerOrderStatisticOpen, setIsModalForCustomerOrderStatisticOpen] = useState(false);
  const [isModalForMenuConversionOpen, setIsModalForMenuConversionOpen] = useState(false);
  const [isModalForOrderPaymentOpen, setIsModalForOrderPaymentOpen] = useState(false);
  const [isModalForFaultyProductsOpen, setIsModalForFaultyProductsOpen] = useState(false);
  // Nouveaux états pour les 4 entités
  const [isModalForRefusalReportOpen, setIsModalForRefusalReportOpen] = useState(false);
  const [isModalForBusyModeUsageReportOpen, setIsModalForBusyModeUsageReportOpen] = useState(false);
  const [isModalForAverageTotalOrderDurationReportOpen, setIsModalForAverageTotalOrderDurationReportOpen] = useState(false);
  const [isModalForPrepTimeReportOpen, setIsModalForPrepTimeReportOpen] = useState(false);

  const [selectedReport, setSelectedReport] = useState<ReportInterface | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [shopId, setShopId] = useState<number | null>(null);

  const methods = useForm();

  const handleFileSelected = (report: ReportInterface) => {
    const fileInput = fileInputRefs.current.get(report.id);
    const file = fileInput?.files[0];
    if (file) {
      const mappings = Object.keys(report.fieldMappings);
      if (mappings.includes("ShopPerformance")) {
        openModalForShopPerformance(report);
      } else if (mappings.includes("CustomerReport")) {
        openModalForCustomerReport(report);
      } else if (mappings.includes("CustomerRetention")) {
        openModalForCustomerOrderStatistic(report);
      } else if (mappings.includes("MenuConversion")) {
        openModalForMenuConversion(report);
      } else if (mappings.includes("OrderPayment")) {
        openModalForOrderPayment(report);
      } else if (mappings.includes("FaultyProducts")) {
        openModalForFaultyProducts(report);
      }
      // Nouveaux cas pour les 4 nouvelles entités
      else if (mappings.includes("RefusalReport")) {
        openModalForRefusalReport(report);
      } else if (mappings.includes("BusyModeUsageReport")) {
        openModalForBusyModeUsageReport(report);
      } else if (mappings.includes("AverageTotalOrderDurationReport")) {
        openModalForAverageTotalOrderDurationReport(report);
      } else if (mappings.includes("PrepTimeReport")) {
        openModalForPrepTimeReport(report);
      } else {
        onUpload(file, report);
      }
    }
  };

  // Fonctions d'ouverture de modale pour les entités existantes
  const openModalForShopPerformance = (report: ReportInterface) => {
    setSelectedReport(report);
    setIsModalForShopPerformanceOpen(true);
  };

  const openModalForCustomerReport = (report: ReportInterface) => {
    setSelectedReport(report);
    setIsModalForCustomerReportOpen(true);
  };

  const openModalForCustomerOrderStatistic = (report: ReportInterface) => {
    setSelectedReport(report);
    setIsModalForCustomerOrderStatisticOpen(true);
  };

  const openModalForMenuConversion = (report: ReportInterface) => {
    setSelectedReport(report);
    setIsModalForMenuConversionOpen(true);
  };

  const openModalForOrderPayment = (report: ReportInterface) => {
    setSelectedReport(report);
    setIsModalForOrderPaymentOpen(true);
  };

  const openModalForFaultyProducts = (report: ReportInterface) => {
    setSelectedReport(report);
    setIsModalForFaultyProductsOpen(true);
  };

  // Fonctions d'ouverture de modale pour les 4 nouvelles entités (se calquent sur ShopPerformance)
  const openModalForRefusalReport = (report: ReportInterface) => {
    setSelectedReport(report);
    setIsModalForRefusalReportOpen(true);
  };

  const openModalForBusyModeUsageReport = (report: ReportInterface) => {
    setSelectedReport(report);
    setIsModalForBusyModeUsageReportOpen(true);
  };

  const openModalForAverageTotalOrderDurationReport = (report: ReportInterface) => {
    setSelectedReport(report);
    setIsModalForAverageTotalOrderDurationReportOpen(true);
  };

  const openModalForPrepTimeReport = (report: ReportInterface) => {
    setSelectedReport(report);
    setIsModalForPrepTimeReportOpen(true);
  };

  const closeModal = () => {
    setIsModalForShopPerformanceOpen(false);
    setIsModalForCustomerReportOpen(false);
    setIsModalForCustomerOrderStatisticOpen(false);
    setIsModalForMenuConversionOpen(false);
    setIsModalForOrderPaymentOpen(false);
    setIsModalForFaultyProductsOpen(false);
    setIsModalForRefusalReportOpen(false);
    setIsModalForBusyModeUsageReportOpen(false);
    setIsModalForAverageTotalOrderDurationReportOpen(false);
    setIsModalForPrepTimeReportOpen(false);
    setSelectedReport(null);
  };

  const handleUploadClick = (report: ReportInterface) => {
    triggerFileInput(report);
  };

  const triggerFileInput = (report: ReportInterface) => {
    const fileInput = fileInputRefs.current.get(report.id) || document.createElement("input");
    fileInput.type = 'file';
    fileInput.onchange = () => { handleFileSelected(report); };
    fileInputRefs.current.set(report.id, fileInput);
    fileInput.click();
  };

  // Gestion des formulaires pour les modales existantes
  const handleFormShopPerformanceSubmit = (data: { startDate: Date; endDate: Date }) => {
    if (selectedReport) {
      const fileInput = fileInputRefs.current.get(selectedReport.id);
      const file = fileInput?.files[0];
      onUpload(file, selectedReport, data.startDate, data.endDate);
      closeModal();
    }
  };

  const handleFormCustomerReportSubmit = (data: { startDate: Date; endDate: Date }) => {
    if (selectedReport) {
      const fileInput = fileInputRefs.current.get(selectedReport.id);
      const file = fileInput?.files[0];
      onUpload(file, selectedReport, data.startDate, data.endDate);
      closeModal();
    }
  };

  const handleFormCustomerRetentionSubmit = (data: { shopId: number }) => {
    if (selectedReport) {
      const fileInput = fileInputRefs.current.get(selectedReport.id);
      const file = fileInput?.files[0];
      onUpload(file, selectedReport, undefined, undefined, data.shopId);
      closeModal();
    }
  };

  const handleFormMenuConversionSubmit = (data: { shopId: number }) => {
    if (selectedReport) {
      const fileInput = fileInputRefs.current.get(selectedReport.id);
      const file = fileInput?.files[0];
      onUpload(file, selectedReport, undefined, undefined, data.shopId);
      closeModal();
    }
  };

  const handleFormOrderPaymentSubmit = (data: { shopId: number }) => {
    if (selectedReport) {
      const fileInput = fileInputRefs.current.get(selectedReport.id);
      const file = fileInput?.files[0];
      onUpload(file, selectedReport, undefined, undefined, data.shopId);
      closeModal();
    }
  };

  const handleFormFaultyProductsSubmit = (data: { startDate: Date; endDate: Date; shopId: number }) => {
    if (selectedReport) {
      const fileInput = fileInputRefs.current.get(selectedReport.id);
      const file = fileInput?.files[0];
      onUpload(file, selectedReport, data.startDate, data.endDate, data.shopId);
      closeModal();
    }
  };

  // Nouveaux formulaires pour les 4 nouvelles entités (se calquent sur ShopPerformance)
  const handleFormRefusalReportSubmit = (data: { startDate: Date; endDate: Date }) => {
    if (selectedReport) {
      const fileInput = fileInputRefs.current.get(selectedReport.id);
      const file = fileInput?.files[0];
      onUpload(file, selectedReport, data.startDate, data.endDate);
      closeModal();
    }
  };

  const handleFormBusyModeUsageReportSubmit = (data: { startDate: Date; endDate: Date }) => {
    if (selectedReport) {
      const fileInput = fileInputRefs.current.get(selectedReport.id);
      const file = fileInput?.files[0];
      onUpload(file, selectedReport, data.startDate, data.endDate);
      closeModal();
    }
  };

  const handleFormAverageTotalOrderDurationReportSubmit = (data: { startDate: Date; endDate: Date }) => {
    if (selectedReport) {
      const fileInput = fileInputRefs.current.get(selectedReport.id);
      const file = fileInput?.files[0];
      onUpload(file, selectedReport, data.startDate, data.endDate);
      closeModal();
    }
  };

  const handleFormPrepTimeReportSubmit = (data: { startDate: Date; endDate: Date }) => {
    if (selectedReport) {
      const fileInput = fileInputRefs.current.get(selectedReport.id);
      const file = fileInput?.files[0];
      onUpload(file, selectedReport, data.startDate, data.endDate);
      closeModal();
    }
  };

  const headers = [
    "Fournisseur",
    "Label",
    "Entités Gérées",
    "Action"
  ];

  const formatFieldMappingsForTooltip = (entity: string, fieldMappings: FieldMappingInterface): JSX.Element => {
    const entriesFields = Object.entries(fieldMappings.fields).filter(([, value]) => value.name !== "");

    return (
      <>
        {fieldMappings.keyIdentifier && (
          <div key={entity + 'keyIdentifier'}>
            {`${tReport(`report.fields.${entity}.keyIdentifier`)}: ${fieldMappings.keyIdentifier.name}`}
          </div>
        )}
        {entriesFields.map(([key, value]) => (
          <div key={entity + key}>
            {`${tReport(`report.fields.${entity}.${key}`)}: ${value.name}`}
          </div>
        ))}
      </>
    );
  };

  const reportData = reports.map(report => ({
    Fournisseur: tProviders(report.provider),
    Label: report.label,
    "Entités Gérées": (
      <div>
        {Object.keys(report.fieldMappings).map(entity => (
          <Tooltip key={entity} content={formatFieldMappingsForTooltip(entity, report.fieldMappings[entity])}>
            <span style={{ cursor: 'help', textDecoration: 'underline', marginRight: '5px' }}>
              {tReport(`report.entities.${entity}`)}
            </span>
          </Tooltip>
        ))}
      </div>
    ),
    Action: (
      <>
        <button onClick={() => onEdit(report)}>
          <FontAwesomeIcon icon={faEdit} />
        </button>
        <button onClick={() => handleUploadClick(report)}>
          <FontAwesomeIcon icon={faUpload} />
        </button>
        <button className="btn-delete" onClick={() => onDelete(report)}>
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </>
    )
  }));

  return (
    <>
      <Table
        headers={headers}
        columnWidths={["10em", "auto", "auto", "10em"]}
        headerAlignments={["left", "left", "left", "center"]}
        contentAlignments={["left", "left", "left", "center"]}
        data={reportData}
      />

      {/* Modale pour ShopPerformance */}
      {isModalForShopPerformanceOpen && selectedReport && (
        <Modal
          isOpen={isModalForShopPerformanceOpen}
          size="large"
          onClose={closeModal}
          title="Sélectionnez la période de l'import"
        >
          <div className="modal-dateRange-container">
            <form onSubmit={(e) => { e.preventDefault(); handleFormShopPerformanceSubmit({ startDate: startDate as Date, endDate: endDate as Date }); }}>
              <DateRangeForm
                startDate={startDate}
                endDate={endDate}
                onStartDateChange={setStartDate}
                onEndDateChange={setEndDate}
                startDateLabel="Date de début"
                endDateLabel="Date de fin"
              />
              <div className="buttons-container">
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
        </Modal>
      )}

      {/* Modale pour CustomerReport */}
      {isModalForCustomerReportOpen && selectedReport && (
        <Modal
          isOpen={isModalForCustomerReportOpen}
          size="large"
          onClose={closeModal}
          title="Sélectionnez la période de l'import"
        >
          <div className="modal-dateRange-container">
            <form onSubmit={(e) => { e.preventDefault(); handleFormCustomerReportSubmit({ startDate: startDate as Date, endDate: endDate as Date }); }}>
              <DateRangeForm
                startDate={startDate}
                endDate={endDate}
                onStartDateChange={setStartDate}
                onEndDateChange={setEndDate}
                startDateLabel="Date de début"
                endDateLabel="Date de fin"
              />
              <div className="buttons-container">
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
        </Modal>
      )}

      {/* Modale pour CustomerRetention */}
      {isModalForCustomerOrderStatisticOpen && selectedReport && (
        <Modal
          isOpen={isModalForCustomerOrderStatisticOpen}
          size="large"
          onClose={closeModal}
          title="Sélectionnez la période de l'import"
        >
          <div className="modal-dateRange-container">
            <form onSubmit={(e) => { e.preventDefault(); handleFormCustomerRetentionSubmit({ shopId: shopId as number }); }}>
              <FormProvider {...methods}>
                <ShopSelect
                  name="shop_id"
                  label="Shop"
                  selectedShopId={shopId}
                  onSelectedShopIdChange={setShopId}
                  defaultValue={undefined}
                  disabled={false}
                />
                <div className="buttons-container">
                  <button type="submit">Submit</button>
                </div>
              </FormProvider>
            </form>
          </div>
        </Modal>
      )}

      {/* Modale pour MenuConversion */}
      {isModalForMenuConversionOpen && selectedReport && (
        <Modal
          isOpen={isModalForMenuConversionOpen}
          size="large"
          onClose={closeModal}
          title="Sélectionnez la période de l'import"
        >
          <div className="modal-dateRange-container">
            <form onSubmit={(e) => { e.preventDefault(); handleFormMenuConversionSubmit({ shopId: shopId as number }); }}>
              <FormProvider {...methods}>
                <ShopSelect
                  name="shop_id"
                  label="Shop"
                  selectedShopId={shopId}
                  onSelectedShopIdChange={setShopId}
                  defaultValue={undefined}
                  disabled={false}
                />
                <div className="buttons-container">
                  <button type="submit">Submit</button>
                </div>
              </FormProvider>
            </form>
          </div>
        </Modal>
      )}

      {/* Modale pour OrderPayment */}
      {isModalForOrderPaymentOpen && selectedReport && (
        <Modal
          isOpen={isModalForOrderPaymentOpen}
          size="large"
          onClose={closeModal}
          title="Sélectionnez la période de l'import"
        >
          <div className="modal-dateRange-container">
            <form onSubmit={(e) => { e.preventDefault(); handleFormOrderPaymentSubmit({ shopId: shopId as number }); }}>
              <FormProvider {...methods}>
                <ShopSelect
                  name="shop_id"
                  label="Shop"
                  selectedShopId={shopId}
                  onSelectedShopIdChange={setShopId}
                  defaultValue={undefined}
                  disabled={false}
                />
                <div className="buttons-container">
                  <button type="submit">Submit</button>
                </div>
              </FormProvider>
            </form>
          </div>
        </Modal>
      )}

      {/* Modale pour FaultyProducts */}
      {isModalForFaultyProductsOpen && selectedReport && (
        <Modal
          isOpen={isModalForFaultyProductsOpen}
          size="large"
          onClose={closeModal}
          title="Sélectionnez la période de l'import"
        >
          <div className="modal-dateRange-container">
            <form onSubmit={(e) => { e.preventDefault(); handleFormFaultyProductsSubmit({ startDate: startDate as Date, endDate: endDate as Date, shopId: shopId as number }); }}>
              <FormProvider {...methods}>
                <DateRangeForm
                  startDate={startDate}
                  endDate={endDate}
                  onStartDateChange={setStartDate}
                  onEndDateChange={setEndDate}
                  startDateLabel="Date de début"
                  endDateLabel="Date de fin"
                />
                <ShopSelect
                  name="shop_id"
                  label="Shop"
                  selectedShopId={shopId}
                  onSelectedShopIdChange={setShopId}
                  defaultValue={undefined}
                  disabled={false}
                />
                <div className="buttons-container">
                  <button type="submit">Submit</button>
                </div>
              </FormProvider>
            </form>
          </div>
        </Modal>
      )}

      {/* Nouvelle modale pour RefusalReport */}
      {isModalForRefusalReportOpen && selectedReport && (
        <Modal
          isOpen={isModalForRefusalReportOpen}
          size="large"
          onClose={closeModal}
          title="Sélectionnez la période de l'import (Refusal Report)"
        >
          <div className="modal-dateRange-container">
            <form onSubmit={(e) => { e.preventDefault(); handleFormRefusalReportSubmit({ startDate: startDate as Date, endDate: endDate as Date }); }}>
              <DateRangeForm
                startDate={startDate}
                endDate={endDate}
                onStartDateChange={setStartDate}
                onEndDateChange={setEndDate}
                startDateLabel="Date de début"
                endDateLabel="Date de fin"
              />
              <div className="buttons-container">
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
        </Modal>
      )}

      {/* Nouvelle modale pour BusyModeUsageReport */}
      {isModalForBusyModeUsageReportOpen && selectedReport && (
        <Modal
          isOpen={isModalForBusyModeUsageReportOpen}
          size="large"
          onClose={closeModal}
          title="Sélectionnez la période de l'import (Busy Mode Usage Report)"
        >
          <div className="modal-dateRange-container">
            <form onSubmit={(e) => { e.preventDefault(); handleFormBusyModeUsageReportSubmit({ startDate: startDate as Date, endDate: endDate as Date }); }}>
              <DateRangeForm
                startDate={startDate}
                endDate={endDate}
                onStartDateChange={setStartDate}
                onEndDateChange={setEndDate}
                startDateLabel="Date de début"
                endDateLabel="Date de fin"
              />
              <div className="buttons-container">
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
        </Modal>
      )}

      {/* Nouvelle modale pour AverageTotalOrderDurationReport */}
      {isModalForAverageTotalOrderDurationReportOpen && selectedReport && (
        <Modal
          isOpen={isModalForAverageTotalOrderDurationReportOpen}
          size="large"
          onClose={closeModal}
          title="Sélectionnez la période de l'import (Average Total Order Duration Report)"
        >
          <div className="modal-dateRange-container">
            <form onSubmit={(e) => { e.preventDefault(); handleFormAverageTotalOrderDurationReportSubmit({ startDate: startDate as Date, endDate: endDate as Date }); }}>
              <DateRangeForm
                startDate={startDate}
                endDate={endDate}
                onStartDateChange={setStartDate}
                onEndDateChange={setEndDate}
                startDateLabel="Date de début"
                endDateLabel="Date de fin"
              />
              <div className="buttons-container">
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
        </Modal>
      )}

      {/* Nouvelle modale pour PrepTimeReport */}
      {isModalForPrepTimeReportOpen && selectedReport && (
        <Modal
          isOpen={isModalForPrepTimeReportOpen}
          size="large"
          onClose={closeModal}
          title="Sélectionnez la période de l'import (Prep Time Report)"
        >
          <div className="modal-dateRange-container">
            <form onSubmit={(e) => { e.preventDefault(); handleFormPrepTimeReportSubmit({ startDate: startDate as Date, endDate: endDate as Date }); }}>
              <DateRangeForm
                startDate={startDate}
                endDate={endDate}
                onStartDateChange={setStartDate}
                onEndDateChange={setEndDate}
                startDateLabel="Date de début"
                endDateLabel="Date de fin"
              />
              <div className="buttons-container">
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ReportsTable;
